import React from "react";
import Nav from "./components/Nav";
import Body from "./components/Body";
import Current from "./components/Current";

function App() {
  return (
    <div className="box-border h-screen flex flex-col bg-neutral-700">
      <Nav />
      <Body />
      <Current />
    </div>
  );
}

export default App;
