import React, { useState } from "react";
import { CgLoadbarSound } from "react-icons/cg";

function Current() {
  const [name, setName] = useState("Current");
  const [artist, setArtist] = useState("Samuele Riva");
  const [img, setImg] = useState("/img/asd.jfif");

  return (
    <div className="box-border p-5 pt-0 h-28">
      <div className="bg-neutral-600 rounded-2xl h-full p-4 flex items-center relative">
        <img src={img} alt="" className="max-h-full h-16 mr-5" />
        <div>
          <p className="font-medium text-lg text-white">{name}</p>
          <p className="text-sm text-gray-100">{artist}</p>
        </div>
        <CgLoadbarSound size={35} className="absolute right-8 text-white" />
      </div>
    </div>
  );
}

export default Current;
