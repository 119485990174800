import React from "react";

function Nav() {
  return (
    <div className="box-border p-5 pb-0">
      <div
        className="box-border shadow-md h-28 bg-contain bg-center bg-no-repeat bg-black rounded-2xl"
        style={{ backgroundImage: `url(/img/nav.jpg)` }}
      ></div>
    </div>
  );
}

export default Nav;
