import React, { useState } from "react";

function Body() {
  const [bg, setBg] = useState([
    "/img/default.webp",
    "/img/default.webp",
    "/img/default.webp",
    "/img/default.webp",
  ]);
  const [names, setNames] = useState(["canzone", "ciao", "asd", "rgiosro"]);
  const [artists, setArtists] = useState(["gino", "beppe", "dnfnfn", "samu"]);

  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-5 p-5 h-full row-span-4">
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5"
        style={{ backgroundImage: `url(${bg[0]})` }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white font-medium text-xl z-10 md:text-2xl">
          {names[0]}
        </p>
        <p className="text-white z-10 md:text-lg">{artists[0]}</p>
      </div>
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5"
        style={{ backgroundImage: `url(${bg[1]})` }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white font-medium text-xl z-10 md:text-2xl">
          {names[1]}
        </p>
        <p className="text-white z-10 md:text-lg">{artists[1]}</p>
      </div>
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5"
        style={{ backgroundImage: `url(${bg[2]})` }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white font-medium text-xl z-10 md:text-2xl">
          {names[2]}
        </p>
        <p className="text-white z-10 md:text-lg">{artists[2]}</p>
      </div>
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5"
        style={{ backgroundImage: `url(${bg[3]})` }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white font-medium text-xl z-10 md:text-2xl">
          {names[3]}
        </p>
        <p className="text-white z-10 md:text-lg">{artists[3]}</p>
      </div>
    </div>
  );
}

export default Body;
